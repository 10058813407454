@import "bootstrap/bootstrap";
@import "hamburgers/hamburgers";

@font-face {
    font-family: Impact;
    src: url('impact.ttf');
    font-weight:400;
}

// variables
$yellow: #fec526;

* {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1, h2, h3 {
    font-family: Impact;
}
// general
.yellow {
    color: $yellow;
}

.bg-black {
    background-color: black;
}

.bg-home {
    background-image: url(../images/home-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-yellow {
    background-color: $yellow;
}

.no-pad {
    padding: 0;
}

.no-side-pad {
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.hide-mobile {
    @media screen and (max-width: 700px) {
        display: none;
    }
}
.badge-last_few {
    background-color: orange;
}

.badge-available {
    background-color: green;
    color: white;
}
.badge-full {
    background-color: gray;
    color: white;
}

.button {
    background-color: black;
    color: $yellow;
    font-family: Impact;
    font-size: 18px;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    padding: 15px 30px;
    &.active {
        background-color: $yellow;
        color: black;
    }
    &:hover {
        color: $yellow;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.navbar-nav {
    button.hamburger {
        outline: none;
    }
    li.nav-item a.nav-link {
        font-family: Impact;
        font-size: 18px;
        color: #231F20;
        text-transform: uppercase;
        padding-left: 1rem;
        padding-right: 1rem;
        &.active {
            color: $yellow;
            text-decoration: underline;
            line-height: 1.5;
        }
    }
}

.jumbotron.home {
    padding-top: 150px;
    padding-bottom: 100px;
    border-radius: 0;
    margin-bottom: 0;
    background-color: transparent;
    color: white;

    img {
        padding-bottom: 50px;
    }
    h1, p, span {
        font-family: Impact;
        line-height: 1.1;
    }

    h1 {
        font-size: 72px;
        margin-bottom: 40px;
    }
    p {
        font-size: 44px;
        line-height: 1;
        margin-bottom: 0;
    }

    @media screen and (max-width: 600px) {
        padding: 50px 0;
        h1 {
            font-size: 36px;
        }
        img {
            max-width: 50%;
            padding-bottom: 25px;
        }
    }
}

.row.sponsors {
    padding: 25px 2rem;
    h2 {
        width: 100%;
    }
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
            max-width: 100%;
        }

        a {
            width: 100%;
        }
    }
}

.mission-statement {
    padding: 80px 0;

    h2 {
        font-family: Impact;
        font-size: 52px;
        line-height: 1;
        margin-bottom: 50px;
    }

    p {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .sponsors {
        img {
            mix-blend-mode: multiply;
        }
    }
}

footer[rel=footer] {
    padding: 50px 0;
    @media screen and (max-width: 600px) {
        padding: 25px 0;
    }

    a {
        color: white;
    }

    .footer-nav {
        width: 100%;
        justify-content: space-between;

        .nav-item {
            border-right: 2px solid white;
            flex-basis: 15%;
            &:last-of-type {
                flex-basis: 30%;
                border: none;
            }

            &.shared {
                display: flex;
                @media screen and (max-width: 1000px) {
                    padding-top: 20px;
                    align-items: center;
                    justify-content: center;

                    &:last-of-type {
                        padding-top: 80px;
                    }
                }
            }
            .nav-link {
            text-align: center;
            color: white;
            font-size: 18px;
                &.inline {
                    display: inline-block;
                    padding: 0px 5px;
                }
            }

            @media screen and (max-width: 1000px) {
                border-right: none;
            }
        }

        .download {
            position: relative;
            &:before {
                content: '';
                background-image: url(../images/Download-App.png);
                background-repeat: no-repeat;
                width: 100%;
                height: 40px;
                position: absolute;
                top: -50px;
                left: 10px;
                @media screen and (max-width: 1000px) {
                    top: 30px;
                    left: 25px;
                }
            }
        }

        img {
            width: 100%;
            max-width: 160px;
        }
    }

    .ack {
        padding: 25px 0;
        p {
            color: white;
            margin-bottom: 10px;
        }
    }
}

.join-carousel {
    overflow: hidden;
    .carousel-control-prev,
    .carousel-control-next {
        width: 120px;
        height: 120px;
        background-color: $yellow;
        position: absolute;
        top: calc(50% - 60px);
        border-radius: 100%;
    }

    .carousel-control-prev {
        left: -60px;
        .carousel-control-prev-icon {
            background-image: url(../images/arrow-left.svg);
            margin-left: 40px;
        }
    }

    .carousel-control-next {
        right: -60px;
        .carousel-control-next-icon {
            background-image: url(../images/arrow-right.svg);
            margin-right: 40px;
        }
    }

    .carousel-indicators {
        @media screen and (max-width: 600px) {
            display: none;
        }
        li {
            height: 30px;
            background-color: transparent;
            border: 1px solid white;
            transition: background-color .2s ease, border-color .2s ease;
            margin: 0 10px;

            &.active {
                background-color: $yellow;
                border-color: $yellow;
            }
        }
    }

}

.route-carousel {
    padding-bottom: 120px;
    background-color: #F7F7F7;
    @media screen and (max-width: 600px) {
        padding-bottom: 300px;
    }
    position: relative;

    .map-modal {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 20px;
        background-color: transparent;
        border: none;
        transition: transform .2s ease;
        img {
            width: 175px;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            transform: scale(1.1);
        }

        @media screen and (max-width: 600px) {
            width: 200px;
            right: calc(50% - 100px);
        }
    }

    .carousel-item {
        img.d-block {
            position: relative;
        }

        img.marker {
            position: absolute;
            z-index: 1000;
            transition: transform .2s ease;
            &:hover {
                transform: scale(1.2);
            }

            @media screen and (max-width: 600px) {
                display: none;
            }

        &[data-target=day-1] { bottom: 26.5%; right: 26% }
        &[data-target=day-2] { bottom: 15%; right: 22% }
        &[data-target=day-3] { bottom: 10%; right: 58%; @media screen and (max-width: 1400px) { right: 59%; } }
        &[data-target=day-4] { bottom: 10%; left: 5.5%; @media screen and (max-width: 1400px) { left: 4%; } }
        &[data-target=day-5] { bottom: 29%; left: 12.5% }
        &[data-target=day-6] { bottom: 42%; left: 34%; @media screen and (max-width: 1400px) { left: 34%; } }
        &[data-target=day-7] { bottom: 50%; left: 19%; @media screen and (max-width: 1400px) { left: 18%; } }
        &[data-target=day-8] { bottom: 65%; left: 15.5% }
        &[data-target=day-9] { bottom: 11%; left: 8%; @media screen and (max-width: 1400px) { left: 7%; } }
        &[data-target=day-10] { bottom: 18%; right: 67%; @media screen and (max-width: 1400px) { right: 65%; } }
        &[data-target=day-11] { bottom: 30%; left: 43%; @media screen and (max-width: 1400px) { left: 42%; } }
        &[data-target=day-12] { bottom: 38%; left: 54%; @media screen and (max-width: 1400px) { left: 52%; } }
        &[data-target=day-13] { bottom: 64%; left: 33%; @media screen and (max-width: 1400px) { left: 31%; } }
        &[data-target=day-14] { bottom: 75%; left: 44%; }
        &[data-target=day-15] { bottom: 80%; left: 30.5%; }
        &[data-target=day-16] { bottom: 89%; left: 35%; @media screen and (max-width: 1400px) { left: 36%; } }
        &[data-target=day-17] { bottom: 12%; left: 47%; @media screen and (max-width: 1400px) { left: 46%; } }
        &[data-target=day-18] { bottom: 43%; left: 33%; @media screen and (max-width: 1400px) { left: 32%; } }
        &[data-target=day-19] { bottom: 77%; left: 28.5%; }
        &[data-target=day-20] { bottom: 82%; left: 41.5%; @media screen and (max-width: 1400px) { left: 40%; } }
        &[data-target=day-21] { bottom: 65%; left: 52.5%; @media screen and (max-width: 1400px) { left: 51.5%; } }
        &[data-target=day-22] { bottom: 48%; left: 52%; @media screen and (max-width: 1400px) { left: 50.5%; } }
        &[data-target=day-23] { bottom: 3%; left: 69.5%; }
        &[data-target=day-24] { bottom: 88%; left: 53.5% }
        &[data-target=day-25] { bottom: 77%; left: 48.5% }
        &[data-target=day-26] { bottom: 56%; left: 63% }
        &[data-target=day-27] { bottom: 44%; left: 40% }
        &[data-target=day-28] { bottom: 20%; left: 49.5% }
        &[data-target=day-29-part-1] { bottom: 20%; left: 62.5% }
       }
    }

    .carousel-indicators {
        bottom: 15px;
        @media screen and (max-width: 600px) {
            display: none;
        }
        @media screen and (max-width: 1000px) {
            margin: 0;
        }
        .button {
            margin: 0 10px;
            outline: none;
            @media screen and (max-width: 600px) {
                flex-basis: 40%;
                margin-bottom: 10px;
                span {
                    display: none;
                }
            }
        }

    }

    .carousel-inner {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}

.join-us {
    padding-top: 75px;
    padding-bottom: 75px;
    @media screen and (max-width: 600px) {
        padding: 50px 15px;
    }
    h1, h2 {
        font-family: Impact;
        line-height: 1;
        margin-bottom: 25px;
    }
    h1 {
        font-size: 56px;
    }
    h2 {
        font-size: 26px;
    }
    p {
        font-size: 19px;
        &.lead {
            font-size: 26px;
        }
    }

    ol {
        padding-left: 1rem;
        li {
            font-size: 19px;
        }
    }
}

.dropdown-row {
    .col {
        display: flex;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        margin-bottom: 25px;
        justify-content: space-between;

        &.no-bottom {
            border-bottom: 0;
        }

        &:hover {
            cursor: pointer;
        }

        button.dropdown-button {
            float: right;
            height: 50px;
            width: 50px;
            border-radius: 100%;
            border: 0px solid black;
            background-color: transparent;
            background-image: url(../images/dropdown-plus.svg);
            background-repeat: no-repeat;
            outline: 0;
            transition: transform .2s ease;

        }

        &[aria-expanded=true] {
            button.dropdown-button {
                transform: rotate(45deg);
            }
        }

        h2 {
            display: inline-block;
            margin-right: auto;
            font-family: Impact;
            font-size: 30px;
            text-transform: uppercase;
            margin-bottom: 0;
            @media screen and (max-width: 600px) {
                font-size: 26px;
            }
        }

        p, span {
            font-family: Impact;
            line-height: 1;
            margin-bottom: 0;
        }
    }
}



.checkbox-container {
    position: relative;
    flex-basis: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
    input {
        display: none;
    }

    input:checked ~ .checkbox {
        background-color: black;
        border: 10px solid $yellow;
    }
}

.checkbox {
    height: 40px;
    width: 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 100%;
}
form.choose-day, form.contact {

    #daysCollapse .days-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 25px;
        .day {
            display: flex;
            border-bottom: 1px solid black;
            flex-basis: 48%;
            min-height: 90px;
            @media screen and (max-width: 600px) {
                flex-basis: 100%;
            }
            p, span {
                font-family: Impact;
            }

            .description {
                flex-basis: 70%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 20px;

                p:first-of-type {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 26px;
                    margin-bottom: 0;
                    flex-basis: 100%;
                    line-height: 1;
                }
            }

            .label {
                flex-basis: 15%;
                display: flex;
                align-items: center;
                .badge {
                    font-size: 14px;
                    padding: 10px;
                    border-radius: 10%;
                }
            }
        }

    }

    em {
        font-size: 14px;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;

        .col {
            flex-basis: 45%;
            @media screen and (max-width: 600px) {
                flex-basis: 100%;
            }

            &.full {
                flex-basis: 100%;
            }
        }
        .text-field {
            margin-bottom: 25px;
            label {
                display: block;
                font-family: Impact;
                font-size: 22px;
                margin-bottom: 10px;
                text-transform: uppercase;
            }

            input, textarea {
                border: 1px solid black;
                border-radius: 5px;
                padding: 10px;
                width: 100%;
                font-size: 19px;
            }
        }
        .check-field {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p {
                margin-bottom: 0;
                font-size: 19px;
                margin-left: 25px;
            }
            .checkbox-container {
                flex-basis: 50px;
                margin-bottom: 0;
            }
        }
    }

    span.message {
        margin-left: 20px;
        font-size: 19px;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }

    padding-bottom: 75px;
}

.route-day {
    .description {
        flex-basis: 80%;
        @media screen and (max-width: 600px) {
            flex-basis: 40%;
        }

        &.top {
            span {
                font-size: 18px;
                margin-bottom: -20px;
            }
            p:first-of-type {
                margin-bottom: 5px;
            }
            p:not(:first-of-type) {
                font-size: 30px;
            }

        }
    }
    .dropdown-row {
        .col {
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .badge {
                font-size: 14px;
                padding: 10px;
                border-radius: 10%;
            }
        }
    }
    .route-container {
        margin: 25px 0;
        @media screen and (max-width: 600px) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

        .details-container {
            flex-basis: 60%;
            width: 60%;
            display: inline-block;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            .description {
                width: 60%;
                display: inline-block;
                @media screen and (max-width: 600px) {
                    width: 100%;
                    padding: 10px 0;
                }

                p, span {
                    font-family: Impact;
                    font-size: 27px;
                    line-height: 1.2;
                }
            }
            .sponsor {
                width: 30%;
                display: inline-block;
                vertical-align: top;
                @media screen and (max-width: 600px) {
                    margin-bottom: 25px;
                }
            }

            .climbs {
                vertical-align: bottom;
                margin: 20px 0;
                @media screen and (max-width: 600px) {
                    margin-top: 20px;
                }
                img {
                    margin-top: -30px;
                }
                img, h3 {
                    display: inline-block;
                }
                h3 {
                    margin-bottom: 20px;
                    font-size: 22px;
                    font-family: Impact;
                }
                p {
                    font-size: 19px;
                    width: 80%;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                }

                em {
                    font-size: 16px;
                    padding-bottom: 20px;
                }

            }
                .climb-join {
                    margin-top: 30px;
                }
        }
        .map-container {
            width: 38%;
            display: inline-block;
            vertical-align: top;

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }
}

.faqs {
    padding-top: 50px;
    padding-bottom: 25px;

    h1 {
        padding-bottom: 25px;
    }

    .faq-row {
        margin-bottom: 0;
    }

    .faq-answer-row {
        padding-top: 20px;
        padding-bottom: 20px;
        p {
            margin-bottom: 0;
        }
    }

}

.contact-us {

    h2 {
        padding-bottom: 15px;
    }
}

.blog {

    .front-page-header {
        padding: 30px 0;
    }

    .blog-row {
        border-top: 1px solid black;
        padding-top: 50px;
        padding-bottom: 50px;

        h2 {
            font-size: 42px;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
    }

    .post-meta {
        h3, a {
            font-family: Impact;
            color: $yellow;
            margin-bottom: 0;
        }

        h3 {
            font-size: 26px;
            text-transform: uppercase;
            margin-bottom: 10px;
            a {
                color: black;
            }
        }
        p.meta {
            font-size: 16px;
        }
    }

    .posts {

        .blog-image {
            height: auto;
        }
        p {
            font-size: 17px;
        }
    }

    .sidebar {
        @media screen and (max-width: 600px) {
            display: none;
        }
        ul {
            border-right: 1px solid black;
            padding-left: 0;
            padding-right: 20px;
            list-style: none;
            height: 100%;

            li {
                border-top: 1px solid black;
                padding: 15px 0;
                &:last-of-type {
                    border-bottom: 1px solid black;
                }

                h3 {
                    font-size: 22px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    a {
                        color: black;
                    }
                }
                p {
                    font-size: 16px;
                    margin-bottom: 0;
                    line-height: 1.4;
                }
            }
        }
    }

    .single-post {
        img {
            margin-bottom: 10px;
            height: auto;
        }

        .gallery-item img {
            width: 100%;
        }

        p img {
            max-width: 100%;
        }
    }
}



.about {
    padding-top: 50px;
    padding-bottom: 50px;
    h1 {
        margin-bottom: 50px;
    }

    .bios {
        img {
            margin-bottom: 40px;
        }
    }
}

.support {
    padding-top: 50px;
    padding-bottom: 50px;
}

.strava-button {
    background-color: #fc4c02;
    color: white;
    text-decoration: none;
    border: 1px solid #ccccd1;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.1s linear, background 0.1s linear, color 0.1s linear;
    font-size: 14px;
    padding: 10px 16px;

    &:hover {
        background-color: #e34402;
        border-color: #e34402;
        color: white;
        text-decoration: none;
    }
}

.countdown-wrapper {
    background-color: #EDEDED;
    overflow: hidden;
    @media screen and (max-width: 600px) {
        padding: 0;
    }
    .countdown-banner {
        .col:first-of-type {
            padding: 20px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            z-index: 2;
            background-color: #EDEDED;

            @media screen and (max-width: 600px) {
                flex-basis: 100%;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100px;
                height: 100%;
                background-color: #EDEDED;
                z-index: -1;
                right: -40px;
                transform: skewX(-30deg);
                @media screen and (max-width: 1000px) {
                    display: none;
                }
            }

            img {
                max-width: 70px;
                mix-blend-mode: multiply;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }

            a {
                background-color: $yellow;
                padding: 5px 10px;
                border-radius: 10px;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
            }
        }

        .col:last-of-type {
            background-color: black;
            padding: 20px 0;
            display: flex;
            justify-content: center;


            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 200px;
                height: 100%;
                background-color: #000;
                right: -200px;
                top: 0;
                z-index: 1000;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }

            #clockdiv, #statdiv {
                font-family: sans-serif;
                color: #fff;
                display: inline-block;
                font-weight: 100;
                text-align: center;
                font-size: 30px;

                .smalltext{
                    font-size: 12px;
                }

                > div {
                    padding: 5px;
                    border-radius: 3px;
                    display: inline-block;

                    span {
                        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        font-size: 22px;
                        color: $yellow;
                        padding: 5px;
                        border-radius: 3px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}